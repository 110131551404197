<template>
  <the-modal>
    <div class="portfolio-admin" v-if="service">
      <form class="text-left" @submit.prevent="submitForm">
        <label class="block">
          <span class="text-xs text-gray-700">Summary</span>
          <editor
            api-key="ryi7etalht0g3h0yl1v8ucpe9304iyxn7irbggeo8txpua5m"
            v-model="service.summary"
            :init="{
              menubar: false,
              plugins: 'lists link code',
              toolbar:
                'styleselect | code | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link',
            }"
          />
          <!-- <textarea
            class="block w-full px-4 py-2 mb-2 form-input"
            v-model="service.summary"
          ></textarea> -->
        </label>
        <label class="block">
          <span class="text-xs text-gray-700">Subtitle</span>
          <input
            class="block w-full px-4 py-2 mb-2 form-input"
            v-model="service.subtitle"
          />
        </label>
        <label class="block">
          <span class="text-xs text-gray-700">Bullet List</span>
          <editor
            api-key="ryi7etalht0g3h0yl1v8ucpe9304iyxn7irbggeo8txpua5m"
            v-model="service.wysiwyg"
            :init="{
              menubar: false,
              plugins: 'lists link code',
              toolbar:
                'styleselect | code | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link',
            }"
          />
        </label>
        <label class="block">
          <span class="text-xs text-gray-700">Link Title</span>
          <input
            class="block w-full px-4 py-2 mb-2 form-input"
            v-model="service.linktitle"
          />
        </label>
        <label class="block">
          <span class="text-xs text-gray-700">Outro Content</span>
          <editor
            api-key="ryi7etalht0g3h0yl1v8ucpe9304iyxn7irbggeo8txpua5m"
            v-model="service.outro"
            :init="{
              menubar: false,
              plugins: 'lists link code',
              toolbar:
                'styleselect | code | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link',
            }"
          />
        </label>
        <input
          type="file"
          @change="onFileSelected"
          name="formImage"
          class="block my-4"
        />
        <button
          class="px-6 py-2 mr-2 text-lg text-gray-200 bg-blue-600 rounded hover:opacity-75"
          type="submit"
        >
          Update
        </button>
        <button
          class="px-6 py-2 mr-2 text-lg text-gray-200 bg-red-600 rounded hover:opacity-75"
          @click.prevent="toggleModal(page)"
        >
          Cancel
        </button>
      </form>
      <the-spinner v-if="loadingState" />
    </div>
  </the-modal>
</template>

<script>
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import TheModal from "@/components/TheModal.vue";
import TheSpinner from "@/components/TheSpinner.vue";
export default {
  props: ["service", "toggleModal", "refreshPage"],
  components: { Editor, TheModal, TheSpinner },
  data() {
    return {
      page: "services",
      type: this.service.type,
      loadingState: false,
      // appUrl: "http://localhost:5001/pomcs-com/us-central1/app",
      appUrl: "https://us-central1-pomcs-com.cloudfunctions.net/app",
    };
  },
  methods: {
    submitForm() {
      let fd = new FormData();
      for (const [key, value] of Object.entries(this.service)) {
        fd.append(key, value);
      }
      if (this.service.selectedFile) {
        this.loadingState = true;
        fd.append(
          "file",
          this.service.selectedFile,
          this.service.selectedFile.name
        );
        fd.append("imagename", this.service.selectedFile.name);
      }
      // if (!this.service.id) {
      //   fd.append("id", "0");
      //   fd.append("sortOrder", 0);
      //   fd.append("page", "services");
      //   fd.append("type", "concierge");
      // }
      axios
        .post(`${this.appUrl}/api/update/dynamicFormData`, fd)
        .then((result) => {
          // console.log("result:", result);
          if (this.loadingState) {
            this.toggleModal(this.page);
            this.refreshPage();
            this.loadingState = false;
          } else {
            this.toggleModal(this.page);
          }
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    onFileSelected(event) {
      this.service.selectedFile = event.target.files[0];
      console.log("onFileSelected triggered", event.target.files[0]);
    },
  },
};
</script>

<style scoped>
.portfolio-admin {
  color: #000;
}
</style>