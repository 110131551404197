<template>
  <div class="w-full home">
    <button
      class="px-3 py-1 mb-4 text-lg text-gray-200 bg-gray-500 rounded hover:opacity-75"
      @click="editItem(service)"
      v-if="isAuthenticated === 'authorized'"
    >
      EDIT PAGE
    </button>
    <h1 class="mb-4 text-2xl uppercase">
      In-Home <span class="text-blue-400">Laundry</span>
    </h1>
    <div v-if="service">
      <div class="text-left header-description" v-html="service.summary"></div>
      <h2 class="my-6 text-lg" v-html="service.subtitle"></h2>
      <h3 class="mb-6 text-3xl text-red-800 uppercase">Free Estimates</h3>
      <div class="flex justify-between bullet-section">
        <div v-html="service.wysiwyg" class=""></div>
        <div v-if="service.imagename" class="img-holder">
          <img
            :src="
              'https://storage.googleapis.com/pomcs-com.appspot.com/' +
              service.imagename
            "
            :alt="service.title + ' image'"
            class="w-full"
          />
        </div>
      </div>
      <div class="mt-6 text-2xl" v-html="service.linktitle"></div>
      <div class="text-left" v-html="service.outro"></div>
    </div>
  </div>

  <admin-services
    :service="service"
    :toggleModal="toggleModal"
    v-if="services_show"
  ></admin-services>
</template>

<script>
import { ref, onBeforeMount, inject } from "vue";
import { getAllOfPageType, isAuthenticated } from "../firebase.js";
import AdminServices from "@/admin/AdminServices.vue";

export default {
  inject: ["global"],
  setup() {
    const service = ref({
      id: "0",
      page: "services",
      type: "laundry",
      sortOrder: 0,
      summary: "",
      subtitle: "",
      wysiwyg: "",
      linktitle: "",
    });
    const services_show = ref(false);
    const selectedItem = ref({});
    onBeforeMount(() => {
      getAllOfPageType("services", "laundry").then((data) => {
        if (data.length) service.value = data[0];
      });
    });
    const toggleModal = (section) => {
      eval(`${section}_show`).value = !eval(`${section}_show`).value;
    };
    const editItem = (item) => {
      const editorVars = ["summary", "wysiwyg", "outro"];
      for (const e in editorVars) {
        if (item && item[e]) {
          item[e] = item[e]
            .replace(/\\n \+\n/g, "")
            .replace(/    /g, "")
            .replace(/\n/g, "");
        }
      }
      selectedItem.value = item;
      service.value = item;
      toggleModal("services");
    };
    return {
      service,
      services_show,
      getAllOfPageType,
      isAuthenticated,
      editItem,
      selectedItem,
      toggleModal,
      AdminServices,
    };
  },
};
</script>

<style lang="scss">
.img-holder {
  max-width: 460px;
}
.header-description p {
  margin-bottom: 1rem;
}
</style>
